exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-donations-tsx": () => import("./../../../src/pages/donations.tsx" /* webpackChunkName: "component---src-pages-donations-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-policy-tsx": () => import("./../../../src/pages/policy.tsx" /* webpackChunkName: "component---src-pages-policy-tsx" */),
  "component---src-pages-students-tsx": () => import("./../../../src/pages/students.tsx" /* webpackChunkName: "component---src-pages-students-tsx" */)
}

